body {
  margin: 0;
  padding: 0;


}
html {
  background: #AF9689;
}
.room-wrapper {
  width: 1280px;
  margin: 0 auto;

  position: relative;
  img {
    width: 100%;
    position: relative;
    display: inline-block;
    z-index: 1;
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  .click-element {
    z-index: 2;
    transform: translate(-50%, -50%);
    position: absolute;
    img {
      width: 50%;
    }
  }

  .nosos {
    z-index: 2;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 80%;
    left: 18%;
    img {
      width: 400px;
    }}
  .pult-upravlenia {
    top: 48%;
    left: 34%;
    img {
      width: 90px;
    }
    .key {
      display: none;
      position: absolute;
      bottom: -2px;
      left: -10px;
      width: 60px;
    }
    &.active {
      img {
        display:inline-block;
      }
      .key {
        display:inline-block;
      }
    }
  }
  .avtomatia-otcl {
    top: 35%;
    left: 34.5%;
    img {
      width: 120px;
    }
    .animate {
      width: 180px;
      position: relative;
      left: -5px;
      bottom: -5px;

    }
  }
  .zvucovoi-izv {
    top: 36%;
    left: 43%;
    img {
      width: 60px;
    }
    .animate {
      position: relative;
      top: -17px;
      right: -2px;
      width: 110px;
    }
  }
  .dver-levaya {
    z-index: 1;
    img {
      width: 120px;
    }
    top: 56%;
    left: 58.5%;
    .animate {
      position: relative;
      left: -118px;
      width: 1300px;
      top: -46px;
      z-index: 0;
    }
  }
  .dver-pravaya {
    z-index: 1;
    img {
      width: 120px;
    }
    top: 56.5%;
    left: 77%;
    .animate {
      z-index: 0;
      position: relative;
      left: -330px;
      width: 1200px;
      top: -26px;
    }
  }
  .poj-izv {
    top: 47%;
    left: 95%;
    img {
      width: 110px;
    }
    .animate {
      position: relative;
      top: 30px;
    }
  }
  .poroshok-ne-yhodi {
    top: 20%;
    left: 57.5%;
    img {
      width: 70px;
    }
  }
  .poroshok-ne-vhodi {
    top: 20%;
    left: 78%;
    img {
      width: 70px;
    }
  }
  .d1 {
    z-index: 1;
    top: 12%;
    left: 16%;
    text-align: right;
    width: 850px;
  }
  .d2 {
    top: 7%;
    left: 47%;
    display: none;
  }
  .d3 {
    top: 17%;
    left: 23%;
    display: none;
  }
  .d4 {
    top: 17%;
    left: 42%;
    display: none;
  }
  .modul-poroshok {
    top: 15%;
    left: 33%;
    img {
      width: 40px;
    }
    .poroshok {
      display: none;
    }
    &.active {
      img {
        display:inline-block;
      }
      .poroshok {
        left: -300px;
        display: inline-block;
        position: absolute;
        width: 1000px;
      }
    }
  }
  .modul {
    z-index: 1;
    top: 46%;
    left: 37%;
    img {
      width: 450px;
    }
    .air {
      display: none;
    }
    &.active {
      img {
        display:inline-block;
      }
      .air {
        left: -150px;
        top: 100px;
        position: absolute;
        width: 1000px;
        display:inline-block;
      }
    }
  }
  .dat1 {
    top:32.5%;
    left: 54.1%;
   }
  .dat2 {
    top:32.5%;
    left: 55%;
  }
  .stelag {
    z-index: 2;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 53%;
    left: 5%;
    img {
      width: 620px;
    }

  }
  .stelag2 {
    z-index: 2;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 60%;
    left: 16%;
    img {
      width: 420px;
    }

  }
}

.click-element {
  .animate {
    display: none;
  }
}

.click-element.active {
  img {
    display: none;
  }
  img.animate {
    display: inline-block;
  }
}

.zoom-mod {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;

  right: 0;
  & > div {
    background: #000;
    width: 150px;
    margin: 0 auto;
    display: flex;
    div {
      cursor: pointer;
      height: 45px;
      color: #fff;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      text-align: center;
    }
  }
}